import { css } from '@emotion/react'
import { Button, Checkbox, Field, Input, Textarea } from '@fluentui/react-components'
import { Controller, useForm } from 'react-hook-form'
import { Add16Regular, Delete16Regular } from '@fluentui/react-icons'
import React, { ComponentType, useEffect } from 'react'
import { ChatbotSettings, ChatbotSettingsWriteReq } from '@apis/my'
import { OpStatus } from '../../../ui/OpStatus'
import { FormInput } from '../../../ui/form/FormInput'
import {useOpState} from "@lib/ui";

export const ChatbotSettingsForm: ComponentType<{
    value: ChatbotSettingsWriteReq
    onSubmit: (data: ChatbotSettingsWriteReq) => Promise<void>,
    submitButtonTitle: string
    isDefault: boolean,
    onDelete?: () => Promise<any>
}> = ({ value, onSubmit, submitButtonTitle, isDefault, onDelete }) => {

    const form = useForm<ChatbotSettings>({
        defaultValues: { ...value },
    })

    const op = useOpState()
    useEffect(() => {
            form.reset(value)
            op.reset()
        },
        [value.name])


    return <form
        css={css`
            > * {
                margin-bottom: 2em;
            }
        `}
        onSubmit={form.handleSubmit(async data => {
            data = {
                ...data,
                answerPrompt: data.answerPrompt.trim(),
                generateQueryPrompt: data.generateQueryPrompt.trim(),
            }
            op.handle(onSubmit(data))
            // onSubmit(data)
        })}>
        <FormInput
            label={'Settings profile name'}
            field={'name'}
            required={true}
            form={form}
            disabled={isDefault}
        />
        <FormInput
            label={'Chat description'}
            field={'chatDescription'}
            form={form}
            required={true}
        />
        <Field label="Query generation prompt">
            <Textarea
                resize={'vertical'}
                {...form.register('generateQueryPrompt', {
                    required: true,
                })}
                rows={4}
            />
        </Field>
        <Field label="Answer prompt">
            <Textarea
                resize={'vertical'}
                {...form.register('answerPrompt', {
                    required: true,
                })}
                rows={5}
            />
        </Field>
        <Field label="Document search limit">
            <Input
                type={'number'}
                defaultValue={form.getValues('searchDocLimit') + ''}
                {...form.register('searchDocLimit', {
                    valueAsNumber: true,
                    required: true,
                })}
            />
        </Field>
        <Controller
            name="questions"
            control={form.control}
            render={({ field }) => {
                const vals: string[] = field.value
                return <div css={css`
                    display: flex;
                    flex-direction: column;

                    > * {
                        margin-bottom: 0.5em;
                    }
                `}>
                    {vals.map((item, index) => (
                        <div key={index}>
                            <Input
                                value={item}
                                css={css`
                                    width: 25em;
                                `}
                                onChange={e => {
                                    const newArray = [...field.value]
                                    newArray[index] = e.target.value
                                    field.onChange(newArray)
                                }}
                            />
                            <Button
                                icon={<Delete16Regular />}
                                css={css`
                                    margin-left: 1em;
                                `}
                                onClick={() => {
                                    field.onChange(vals.filter((_, i) => i !== index))
                                }}></Button>
                        </div>
                    ))}
                    <Button
                        type="button"
                        icon={<Add16Regular />}
                        css={css`
                            width: 5em;
                        `}
                        onClick={() => {
                            const newArray = [...field.value, ''] // Add an empty string to array
                            field.onChange(newArray)
                        }}
                    >
                    </Button>
                </div>
            }}
        />
        <hr />
        <h4>Compensations</h4>
        <Field label="Query unquoting" orientation={'horizontal'}>
            <Controller
                name="compensationQueryUnquoting"
                control={form.control}
                render={({ field }) =>
                    <Checkbox
                        checked={field.value}
                        onChange={e => {
                            const val = e.target.checked
                            field.onChange(val)
                        }} />
                } />
        </Field>
        <div css={css`
            > * {
                margin-right: 0.5em;
            }
        `}>
            <Button type={'submit'} appearance={'primary'}>
                {submitButtonTitle}
            </Button>

            {(onDelete && !isDefault) && <Button onClick={() => {
                op.handle(onDelete())
            }}
            >Delete</Button>}
        </div>
        <div>
            <OpStatus op={op} />
        </div>

    </form>
}
