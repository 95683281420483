import React, { ComponentType, ReactNode } from 'react'
import { Button } from '@fluentui/react-components'
import { Delete16Regular } from '@fluentui/react-icons'
import { css } from '@emotion/react'
import { OpStatus } from './OpStatus'
import { useOpState } from "@lib/ui"

export const DeleteButton: ComponentType<{ onDelete: () => Promise<void> }> = ({ onDelete }) => {

    const op = useOpState()

    let cont: ReactNode

    if (op.pending)
        cont = <OpStatus
            css={css`
                margin-top: 0.3em;
            `}
            op={op} spinnerSize={'tiny'} />
    else
        cont = <Button
            icon={<Delete16Regular />}
            css={css` `}
            onClick={async () => {
                op.handle(onDelete())
            }}></Button>

    return <div css={css`
        width: 2.2em;
        height: 2.2em;
    `}>
        {cont}
    </div>
}
