import { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-drawer'
import {
    Toast, Toaster, ToastTitle, ToastTrigger, useId,
    useToastController,
} from '@fluentui/react-components'
import { setFluentUiComponents } from '@lib/ui'

// For some reason these components do not work when imported if not in the file which is in subdir of the app root
setFluentUiComponents({
    Drawer, DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Toast, Toaster, ToastTitle, ToastTrigger,
    useId,
    useToastController,
})

