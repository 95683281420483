import type { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-drawer'
import type {
    Toast, Toaster, ToastTitle, ToastTrigger, useId,
    useToastController,
} from '@fluentui/react-components'


export type FluentUiComps = {
    Drawer: typeof Drawer
    DrawerBody: typeof DrawerBody
    DrawerHeader: typeof DrawerHeader
    DrawerHeaderTitle: typeof DrawerHeaderTitle
    Toast: typeof Toast
    ToastTitle: typeof ToastTitle,
    ToastTrigger: typeof ToastTrigger,
    Toaster: typeof Toaster
    useId: typeof useId,
    useToastController: typeof useToastController
}

export let FluentUi: FluentUiComps

export const setFluentUiComponents = (comps: FluentUiComps) => {
    FluentUi = comps
}
