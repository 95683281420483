import { ComponentType } from 'react'

export const ErrorMsg: ComponentType<{
    children?: string | undefined
    shown?: boolean
    error?: Error,
    className?: string
}> = ({ children, shown, error, className }) =>
    error || shown ? (
        <span
            className={className}

        >
            {children || `Failed: ${error ? `${error.message}` : ''}`}
        </span>
    ) : null
