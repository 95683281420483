import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../ctx/MyContext'
import { AdminPage } from '../AdminPage'
import { DrawerPanel, useDrawerPanelState } from '../../ui/DrawerPanel'
import { ChatbotSettings, ChatbotSettingsWriteReq } from '@apis/my'
import { ChatbotSettingsForm } from '../chatbot/settings/ChatbotSettingsForm'
import { observer } from 'mobx-react'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { collectionScreenPath } from '../CollectionSelector'
import { RadioGroup, simpleOptions } from '@lib/ui'


// const emptyValue: ChatbotSettingsWriteReq = {
//     name: '',
//     chatDescription: '...',
//     answerPrompt: '...',
//     generateQueryPrompt: '{{ question }} ...',
//     searchDocLimit: 3,
//     questions: [],
//     compensationQueryUnquoting: false,
//
// }

const emptyValue: ChatbotSettingsWriteReq = {
    name: '',
    chatDescription: '',
    answerPrompt: '',
    generateQueryPrompt: '',
    searchDocLimit: 3,
    questions: [],
    compensationQueryUnquoting: false,

}

export const ChatbotSettingPage = observer(() => {

    const { chat } = useAppContext()

    const store = chat.settingsStore

    const loaded = !!store.all
    const items = (store.all || [])

    const [sel, setSel] = useState<ChatbotSettings>()

    useEffect(() => {
        if (loaded) {
            setSel(items[0])
        }
    }, [
        loaded, // When data loaded
        items.length, // When an item deleted
    ])


    const drawer = useDrawerPanelState()

    return (
        <AdminPage title={'Chatbot settings profiles'}
                   titleButton={{
                       title: 'Add new',
                       onClick: () =>
                           drawer.show(),

                   }}
        >
            <p>
                Use <Link to={collectionScreenPath}>
                Collection detail page</Link> to attach the settings profile to a Collection
            </p>
            {sel &&
                <>
                    <RadioGroup value={sel.name}
                                horizontal={true}
                                onChange={name => {
                                    const s = items.find(i => i.name === name)
                                    setSel(s)
                                }}
                                options={simpleOptions(items.map(i => i.name))}
                    />
                    <div css={css`
                        width: 60em;
                    `}>

                        <ChatbotSettingsForm value={sel}
                                             isDefault={sel.isDefault}
                                             submitButtonTitle={'Update'}
                                             onSubmit={s => store.update(sel.id, s)}
                                             onDelete={async () => {
                                                 await store.delete(sel.id)
                                             }}
                        />
                    </div>
                </>
            }
            <DrawerPanel state={drawer}
                         width={'60em'}
                         title={'Create a new Chatbot settings profile'}>
                <ChatbotSettingsForm value={emptyValue}
                                     isDefault={false}
                                     submitButtonTitle={'Create'}
                                     onSubmit={async d => {
                                         const neu = await store.add(d)
                                         setSel(neu)
                                         drawer.close()
                                     }} />
            </DrawerPanel>
        </AdminPage>
    )
})

