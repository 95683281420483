import { MyApi } from './api'
import { useEffect } from 'react'
import { useApi } from './useApi'
import {OpState, useOpState} from "@lib/ui";

export const useApiData = <D>(
    query: (api: MyApi) => Promise<D>,
    opts: {
        keys?: any[]
        onFetch?: (d: D) => void
    } = {},
): OpState<D> & { refetch: () => void } => {
    const op = useOpState<D>()
    const api = useApi()

    const run = () => {
        op.handle(query(api)).then((data) => {
            opts.onFetch && opts.onFetch(data)
        })
    }

    useEffect(() => {
        run()
    }, opts.keys || [])

    return {
        ...op,
        refetch: run,
    }
}
