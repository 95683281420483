import { Delete24Regular, SparkleFilled } from '@fluentui/react-icons'

import styles from './Chat.module.css'

import { Answer, AnswerError, AnswerLoading } from '../../components/Answer/index'
import { QuestionInput } from '../../components/QuestionInput/index'
import { ExampleList } from '../../components/Example/index'
import { AnalysisPanel, AnalysisPanelTabs } from '../../components/AnalysisPanel/index'
import { useAppTheme } from '../../branding/useTheme'
import { Button } from '../../ui/Button'
import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { useCollectionStore } from '../SearchCollectionsStore'
import { useAppContext } from '../../ctx/MyContext'
import { useChatStore } from '../ChatStore'
import { ComponentType, useEffect, useRef, useState } from 'react'
import { useText } from '../../texts'
import { AskResponse } from '@apis/my'
import { Rating } from './Rating'
import {useOpState} from "@lib/ui";

export const Chat = observer(() => {
    const ctx = useAppContext()
    const chat = useChatStore()
    const col = useCollectionStore()
    const op = useOpState()

    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null)

    useEffect(
        () =>
            chatMessageStreamEnd.current?.scrollIntoView({
                behavior: 'smooth',
            }),
        [op.pending],
    )

    const texts = useText()
    let questionPlaceholder = texts.typeANewQuestion

    const exampleQuestion = chat.settings?.questions[0]

    if (exampleQuestion) {
        questionPlaceholder += ` (${texts.eg} ${exampleQuestion})`
    }

    const desc = chat.settings?.chatDescription
    // TODO move to texts
    const mainText = desc && 'Über ' + desc + ' chatten' // Originally it was 'Chat about ...'

    const submitMsg = (msg: string) => {
        op.handle(chat.submitMsg(msg))
    }

    const askAgain = (idx: number) => {
        op.handle(chat.askAgain(idx))
    }

    // TODO improve later more
    const [analyzedAnswer, setAnalyzedAnswer] = useState<AskResponse | undefined>()
    const [analyzedCitation, setAnalyzedCitation] = useState<string | undefined>()
    const [activeAnlTab, setActiveAnlTab] = useState(AnalysisPanelTabs.ThoughtProcessTab)

    if (col.items?.length === 0)
        return (
            <div
                css={css`
                    margin: auto;
                `}
            >
                <h2>No knowledge base. Please create one.</h2>
            </div>
        )

    const t = useAppTheme()

    const clearThoughtProcess = () => {
        setAnalyzedCitation(undefined)
        setAnalyzedAnswer(undefined)
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                flex: 1;
            `}
        >
            <div
                css={css`
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            margin-left: 10em;
                            padding-right: 1em;
                        `}
                    >
                        {chat.hasStarted() && <Button
                            icon={Delete24Regular}
                            disabled={!chat.hasStarted()}
                            css={css`
                                margin-right: 10em;
                            `}
                            onClick={() => {
                                chat.clearChat()
                                clearThoughtProcess()
                            }}
                        >
                            Clear chat
                        </Button>}
                    </div>
                </div>
                <div className={styles.chatRoot}>
                    <div className={styles.chatContainer}>
                        {!chat.hasStarted() ? (
                            <ChatNotStartedScreen
                                title={mainText}
                                subtitle={texts.askAnything}
                                onExampleQuestionClicked={submitMsg}
                            />
                        ) : (
                            <div
                                css={css`
                                    flex-grow: 1;
                                    max-height: 1024px;
                                    max-width: 1028px;
                                    width: 100%;
                                    overflow-y: auto;
                                    padding-left: 24px;
                                    padding-right: 24px;
                                    display: flex;
                                    flex-direction: column;
                                `}
                            >
                                {chat.msgs.map((msg, index) => (
                                    <div key={index}>
                                        {msg.role === 'user' && (
                                            <UserChatMessage
                                                message={msg.content}
                                            />
                                        )}
                                        {msg.role === 'assistant' && (
                                            <div
                                                css={css`
                                                    margin-bottom: 20px;
                                                    max-width: 80%;
                                                    display: flex;
                                                    flex-direction: column;
                                                    min-width: 500px;
                                                `}
                                            >
                                                <Answer
                                                    key={index}
                                                    answer={msg.res}
                                                    // TODO
                                                    isSelected={false}
                                                    onCitationClicked={(
                                                        cit,
                                                    ) => {
                                                        // TODO not optimal (legacy code)
                                                        setAnalyzedAnswer(
                                                            msg.res,
                                                        )
                                                        setAnalyzedCitation(cit)
                                                        setActiveAnlTab(
                                                            AnalysisPanelTabs.CitationTab,
                                                        )
                                                    }}
                                                    onThoughtProcessClicked={() => {
                                                        setAnalyzedAnswer(
                                                            msg.res,
                                                        )
                                                        setActiveAnlTab(
                                                            AnalysisPanelTabs.ThoughtProcessTab,
                                                        )
                                                    }}
                                                    onSupportingContentClicked={() => {
                                                        setAnalyzedAnswer(
                                                            msg.res,
                                                        )
                                                        setActiveAnlTab(
                                                            AnalysisPanelTabs.SupportingContentTab,
                                                        )
                                                    }}
                                                    // TODO first we need to take care of anlysis update handling
                                                    onAnswerAgain={() => {
                                                        askAgain(index - 1)
                                                        // TODO this jumps around
                                                        setAnalyzedAnswer(
                                                            undefined,
                                                        )
                                                    }}
                                                />
                                                {!msg.res.noKnowledge &&
                                                    <RatingPane css={css`
                                                        margin-top: 1em;
                                                    `} />}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {op.pending && (
                                    <div
                                        css={css`
                                            max-width: 500px;
                                            margin-bottom: 20px;
                                        `}
                                    >
                                        <AnswerLoading />
                                    </div>
                                )}
                                {op.error ? (
                                    <>
                                        <div
                                            css={css`
                                                max-width: 500px;
                                                margin-bottom: 20px;
                                            `}
                                        >
                                            <AnswerError
                                                error={op.error.toString()}
                                                onRetry={() => {
                                                    clearThoughtProcess()
                                                    chat.askAgain(
                                                        chat.msgs.length - 1,
                                                    )
                                                }}
                                            />
                                        </div>
                                    </>
                                ) : null}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}

                        {chat.canEnterMsg && (
                            <div className={styles.chatInput}>
                                <QuestionInput
                                    clearOnSend
                                    placeholder={questionPlaceholder}
                                    disabled={op.pending}
                                    onSend={submitMsg}
                                />
                            </div>
                        )}
                    </div>

                    {analyzedAnswer && (
                        <AnalysisPanel
                            activeCitation={analyzedCitation}
                            answer={analyzedAnswer}
                            activeTab={activeAnlTab}
                            onActiveTabChanged={setActiveAnlTab}
                            citationHeight="810px"
                            className={styles.chatAnalysisPanel}
                        />
                    )}
                </div>
            </div>
        </div>
    )
})

const ChatNotStartedScreen: ComponentType<{
    title?: string
    subtitle: string
    onExampleQuestionClicked: (q: string) => void
}> = ({ title, subtitle, onExampleQuestionClicked }) => {
    return (
        <div className={styles.chatEmptyState}>
            <SparkleFilled
                fontSize={'120px'}
                primaryFill={useAppTheme().color}
                aria-hidden="true"
                aria-label="Chat logo"
            />
            <h2
                css={css`
                    font-size: 4rem;
                    font-weight: 600;
                    margin-top: 0;
                    margin-bottom: 30px;
                    line-height: 100%;
                `}
            >
                {title}
            </h2>
            <h3 className={styles.chatEmptyStateSubtitle}
                css={css`
                    font-weight: 400;
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                `}
            >{subtitle}</h3>
            <ExampleList onExampleClicked={onExampleQuestionClicked} />
        </div>
    )
}

const UserChatMessage = ({ message }: { message: string }) => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: flex-end;
                margin-bottom: 20px;
                max-width: 80%;
                margin-left: auto;
            `}
        >
            <div
                css={css`
                    padding: 20px;
                    background: rgba(var(--main-color-rgb), 0.2);
                    border-radius: var(--border-radius);
                    box-shadow: var(--box-shadow);
                    outline: transparent solid 1px;
                `}
            >
                {message}
            </div>
        </div>
    )
}


const RatingPane: ComponentType<{ className?: string }> = observer(({ className }) => {
    const chat = useChatStore()
    const t = useAppTheme()

    return <div css={css`
        display: flex;
        justify-content: flex-end;
    `}
                className={className}
    >

        {chat.canRate &&
            <div css={css`
                font-size: 1.0em;
            `}>
                {/*<span css={css`*/}
                {/*    margin-right: 1em;*/}
                {/*`}>*/}
                {/*Rate the answer*/}
                {/*</span>*/}
                <Rating max={chat.ratingScale[4]}
                        value={chat.submittedRating}
                        onRatingSelected={
                            num => chat.rateLastAnswer(num)
                        } />
            </div>}
    </div>

})
