import React, { ComponentType } from 'react'
import { Radio, RadioGroup as FuiRadioGroup } from '@fluentui/react-components'
import { Option } from './options'


export const RadioGroup: ComponentType<{
    options: Option[]
    value?: string
    horizontal?: boolean
    disabled?: boolean,
    onChange: (val: string) => void
}> = ({ options, value, onChange, horizontal, disabled }) => (
    <FuiRadioGroup
        disabled={disabled}
        value={value}
        layout={horizontal ? 'horizontal' : undefined}
        onChange={(_, v) => onChange(v.value)}
    >
        {options.map((o) => (
            <Radio key={o.value} value={o.value} label={o.label} />
        ))}
    </FuiRadioGroup>
)
