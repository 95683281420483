export type Option = {
    value: string
    label: string
}

export const simpleOptions = (vals: string[]): Option[] =>
    vals.map((value) => ({ value, label: value }))

export const enumOptions = <V extends string, >(enmDef: Record<string, V>, opts?: { sort?: V[] }): Option[] => {
    let res = Object.keys(enmDef).map(key => ({ label: key, value: enmDef[key] }))
    if (opts?.sort) {
        const sort = opts.sort
        res = res.map(o => {
            let idx = sort.indexOf(o.value)
            if (idx < 0)
                idx = 1_000_000
            return {
                o,
                idx
            }
        }).sort((o1, o2) => o1.idx - o2.idx)
            .map(r => r.o)

    }
    return res
}
